import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const StreetZoology = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Street Zoology" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>
            Street Zoology: The Best Animal References in Sahbabii's Discography
          </p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Nov. 27, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            So...
            <a
              href="http://shoya.co/shoyaright/vol1/baby-rappers"
              target="_blank"
              rel="noopener noreferrer"
            >
              I like Sahbabii's music
            </a>{" "}
            a lot.
          </p>
          <p>
            His debut album <i>Do It For Demon</i> dropped recently (now
            available to stream on{" "}
            <a
              href="https://music.apple.com/us/album/do-it-for-demon/1590560975"
              target="_blank"
              rel="noopener noreferrer"
            >
              Shoya Music
            </a>
            ), which prompted me to wanna revisit his earlier work and analyze
            what I like most about his music and persona: the frequent,
            creative, and often hilarious use of animal imagery.
          </p>
          <p>
            As someone who loves animals, his approach resonates with me. The
            zoological bars have become one of his calling cards (along with
            sex, gun violence, and{" "}
            <a
              href="http://shoya.co/shoyaright/vol2/top5-anime"
              target="_blank"
              rel="noopener noreferrer"
            >
              anime
            </a>
            ), and they probably do the heaviest lifting as far as setting him
            apart from his peers. You could even call him the trailblazer of a
            distinct subgenre — 'animal rap', maybe?
          </p>
          <p>
            I combed carefully through{" "}
            <a
              href="https://music.apple.com/us/album/s-a-n-d-a-s/1240363512"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>S.A.N.D.A.S.</i>
            </a>{" "}
            (2017),{" "}
            <a
              href="https://music.apple.com/us/album/squidtastic/1434235523"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Squidtastic</i>
            </a>{" "}
            (2018),{" "}
            <a
              href="https://music.apple.com/us/album/barnacles/1521288217"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i>Barnacles</i>
            </a>{" "}
            (2020), and now <i>Do It For Demon</i> (2021) to try and grasp the
            full scope of Saaheem Valdery's beast expertise. I'm not exactly
            sure how to present my findings, so I'll just start by listing each
            distinct animal referenced across each project:
          </p>
          <p>
            <span className="highlight">S.A.N.D.A.S.</span>:{" "}
            <span className="editorsNote">anacondas</span>,{" "}
            <span className="editorsNote">cheetahs</span>,{" "}
            <span className="editorsNote">apes</span>,{" "}
            <span className="editorsNote">snakes</span>,{" "}
            <span className="editorsNote">tarantulas</span>,{" "}
            <span className="editorsNote">King Kong</span>,{" "}
            <span className="editorsNote">dinosaurs</span>,{" "}
            <span className="editorsNote">foxes</span>,{" "}
            <span className="editorsNote">cats</span>,{" "}
            <span className="editorsNote">marsupials</span>,{" "}
            <span className="editorsNote">dogs</span>,{" "}
            <span className="editorsNote">geese</span>,{" "}
            <span className="editorsNote">Harambe</span>,{" "}
            <span className="editorsNote">Simba</span>,{" "}
            <span className="editorsNote">amphibians</span>,{" "}
            <span className="editorsNote">fish</span>,{" "}
            <span className="editorsNote">Caesar</span> (from the{" "}
            <i>Planet of the Apes</i> franchise),{" "}
            <span className="editorsNote">Titanoboa</span>,{" "}
            <span className="editorsNote">rats</span>,{" "}
            <span className="editorsNote">Master Splinter</span>,{" "}
            <span className="editorsNote">elephants</span>,{" "}
            <span className="editorsNote">black panthers</span>
          </p>
          <p>
            <span className="highlight">Squidtastic</span>:{" "}
            <span className="editorsNote">squid</span>,{" "}
            <span className="editorsNote">shrimp</span>,{" "}
            <span className="editorsNote">barnacles</span>,{" "}
            <span className="editorsNote">Barney</span>,{" "}
            <span className="editorsNote">cats</span>,{" "}
            <span className="editorsNote">Garfield</span>,{" "}
            <span className="editorsNote">rats</span>,{" "}
            <span className="editorsNote">dragons</span>,{" "}
            <span className="editorsNote">snakes</span>,{" "}
            <span className="editorsNote">apes</span>,{" "}
            <span className="editorsNote">penguins</span>,{" "}
            <span className="editorsNote">ponies</span>,{" "}
            <span className="editorsNote">hippopotamus</span>,{" "}
            <span className="editorsNote">honey bees</span>,{" "}
            <span className="editorsNote">Winnie the Pooh</span>
          </p>
          <p>
            <span className="highlight">Barnacles</span>:{" "}
            <span className="editorsNote">barnacles</span>,{" "}
            <span className="editorsNote">dogs</span>,{" "}
            <span className="editorsNote">squid</span>,{" "}
            <span className="editorsNote">goats</span>,{" "}
            <span className="editorsNote">giraffes</span>,{" "}
            <span className="editorsNote">elephants</span>,{" "}
            <span className="editorsNote">hippopotamus</span>,{" "}
            <span className="editorsNote">rhinoceros</span>,{" "}
            <span className="editorsNote">monkeys</span>,{" "}
            <span className="editorsNote">apes</span>,{" "}
            <span className="editorsNote">wildebeest</span>,{" "}
            <span className="editorsNote">leopards</span>,{" "}
            <span className="editorsNote">pigs</span>,{" "}
            <span className="editorsNote">mice</span>,{" "}
            <span className="editorsNote">minks</span>,{" "}
            <span className="editorsNote">Scooby-Doo</span>,{" "}
            <span className="editorsNote">sharks</span>,{" "}
            <span className="editorsNote">crawfish</span>,{" "}
            <span className="editorsNote">Rufus</span> (the{" "}
            <span className="editorsNote">naked mole rat</span> from Shoya's{" "}
            <i>Kim Possible</i>), <span className="editorsNote">catfish</span>,{" "}
            <span className="editorsNote">frogs</span>,{" "}
            <span className="editorsNote">marsupials</span>,{" "}
            <span className="editorsNote">lions</span>,{" "}
            <span className="editorsNote">mosquitos</span>,{" "}
            <span className="editorsNote">komodo dragons</span>
          </p>
          <p>
            <span className="highlight">Do It For Demon</span>:{" "}
            <span className="editorsNote">wolves</span>,{" "}
            <span className="editorsNote">chickens</span>,{" "}
            <span className="editorsNote">bears</span>,{" "}
            <span className="editorsNote">leeches</span>,{" "}
            <span className="editorsNote">Godzilla</span>,{" "}
            <span className="editorsNote">King Kong</span>,{" "}
            <span className="editorsNote">hawks</span>,{" "}
            <span className="editorsNote">snakes</span>,{" "}
            <span className="editorsNote">possums</span>,{" "}
            <span className="editorsNote">dogs</span>,{" "}
            <span className="editorsNote">cats</span>,{" "}
            <span className="editorsNote">rats</span>,{" "}
            <span className="editorsNote">zebras</span>,{" "}
            <span className="editorsNote">salmon</span>,{" "}
            <span className="editorsNote">shrim</span>,{" "}
            <span className="editorsNote">Red Lobsters</span> [sic],{" "}
            <span className="editorsNote">lions</span>,{" "}
            <span className="editorsNote">gorillas</span>,{" "}
            <span className="editorsNote">fish</span>,{" "}
            <span className="editorsNote">cockatoos</span>,{" "}
            <span className="editorsNote">turtles</span>,{" "}
            <span className="editorsNote">squid</span>
          </p>
          <p>
            I'd be remiss, though, if I didn't present a few of my favorites
            with more context (<i className="editorsNote">content warning</i>:
            sexual imagery involving animals):{" "}
          </p>
          <p>
            "Bitch I'm King of the Jungle, I eat anacondas", "I'm in the jungle
            like Steve Irwin, you in the jungle like Steve Urkel", "Long neck,
            she a sexy goose", "I wanna fuck an amphibian", "I'm vegan, throw
            that apple back", "Ape it, ape it, ape it, ape it // Titanoboa, imma
            snake it", "Animal planet Ferrari // My chain, my neck is safari",
            "Pop your black panther" (s/o T3), "Like a dog in the trash, went
            through some shit", "I was born when a squid fucked a goat", "I
            don't support the zoo, free my apes out the cage", "That ass vibrate
            like a bumble bee (bzzz)", "You play with komodo dragons, bitch, I
            won't recommend it", "We gon pull up on they block and grow a
            rainforest", and this last one I won't type out but just know that
            my man very successfully rhymed "cock or two", with "cockatoo"...
          </p>
          <p>
            But wait...should we be worried? I noticed that on this latest
            release, we actually get the least frequent animal references so
            far. In fact, on each of the first three projects, over 90% of the
            tracks (91.7%, 91.7%, and 93.8%, respectively, to be precise)
            contain at least one animal reference, compared to just 60% on{" "}
            <i>Do It For Demon</i>. That would be concerning, but it jibes with
            another trend I noticed during the course of my research — that is,
            that the darker the subject matter of the song, the less likely it
            is that it'll contain any colorful animal references at all (by
            'colorful' I'm excluding snakes and rats, for obvious reasons). And
            considering that most of this album pays homage to his fallen friend
            Demon, it makes sense that the zoologist took on a smaller role
            here.
          </p>
          <p>
            So essentially, my working theory is that the happier Sahbabii is,
            the more he talks about animals (that's true for me, too). And I
            hope, for all our sakes, that my good Pisces brother sees better
            days.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default StreetZoology
